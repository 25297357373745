<template>
  <v-container class="fullpage d-flex">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="6">
        <h1 class="text-h2 text-lg-h1">{{ $t('forms.title') }}</h1><br>
        <p>{{ $t('forms.p[0]') }}</p>
        <p class="mb-7">{{ $t('forms.p[1]') }} <strong><i>{{ $t('forms.p[2]') }}</i></strong></p>
        <h2 class="text-h5 font-weight-bold mb-2">{{ $t('forms.p[3]') }}</h2>
        <p>{{ $t('forms.p[4]') }}</p>
        <h3 class="accent--text">{{ $t('forms.p[5]') }}</h3>
        <p>{{ $t('forms.p[6]') }}<br>
           <v-btn color="primary" href="https://www.facebook.com/kontomourtos/posts/141074087555815" target="_blank" text rounded>{{ $t('forms.btn[0]') }}</v-btn></p>
        <h3 class="accent--text">{{ $t('forms.p[7]') }}</h3>
        <p class="mb-9">{{ $t('forms.p[8]') }}</p>
        <v-btn color="primary" href="https://www.kontomourtos.gr/forms" target="_blank" rounded outlined>{{ $t('forms.btn[1]') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'forms'
}
</script>